.Key {
    align-items: center;
    background: var(--docsearch-key-gradient);
    border-radius: 2px;
    box-shadow: var(--docsearch-key-shadow);
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 0.4em;
    padding-bottom: 1px;
    min-width: 20px;
    --docsearch-key-shadow: inset 0 -2px 0 0 #d2c8b8, inset 0 0 1px 1px #fff, 0 1px 2px 1px rgba(90, 81, 30, 0.4);
    --docsearch-key-gradient: linear-gradient(-225deg, #e4e0d5, #f8f8f8);
}

.Key.Down svg {
    transform: rotate(180deg);
}

.Key svg {
    color: #4c473b;
}

.Key .KeyText {
    font-size: 12px;
    padding: 0px 3px 3px 3px;
    color: #4c473b;
}
