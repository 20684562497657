:root {
    /* font family */
    font-family: "Soleil", sans-serif;
    /* font-family: "DM Sans", sans-serif; */
    /* font-family: "Figtree", sans-serif; */
    /* font-family: "Inter", sans-serif; */
    /* font-family: "Plus Jakarta Sans", sans-serif; */
    font-weight: 400;
    font-style: normal;

    /* margin */
    margin: 0px;
    padding: 0px;

    /* current main color scheme */
    /** BASE COLORS */
    --main_color: #001733;
    --main_font_color: #001733;
    --blackFont: #1e1e1e;
    --main_accent: #0076dd;
    --accent_glowingBlue: #0c77cf;
    --accent_glowingBlueBright: #0076dd;
    --scrollBar_color: #525252; /* NOT USED */
    --optionalResource: #6022e3;
    --accent_blue_lightBG: #e5f3ff;
    --accent_blue_lightBorder: #bdd9f1;
    --notificationRed: #ca0000;

    /** RESOURCES */
    --eventBlack: #0e0e0e;
    --quizColor: #005B9D;
    --quizColorHover: #661aa9;
    --quizColorSelected: #841eba;
    --quizTileBorderColor: #e9d0ff;
    --quizColorMatchBGSelected: #f7eeff;
    --quizColorMatchBGSelectedHover: #e6d7f3;
    --correctAnswerBG: #f6fff4;
    --correctAnswerBorder: #009d19;
    --wrongAnswerBG: #ffe5e0;
    --wrongAnswerBorder: #b20000;
    --quizOptionBG: #e9e2d4;
    --quizOptionBGHover: #dad2c4;
    --quizOptionFontColor: #58534a;
    --quizOptionBorderColor: #e9e3d5;

    /** SPACE_LOADER */
    --spc_icon_backgroundColor: var(--mainNav_backgroundColor);

    /** CANVAS */
    --canvas: #fcf9f4; /* old #F8F6F2 */
    --canvasRGB: 252, 249, 244;
    --canvas_hover: #ebe5da;
    --canvas_btn: #e6dfd2;
    --canvas_btn_border: #cac2b3;
    --canvas_btn_hover: #dbd4c3;
    --canvas_btn_active: #cdc7b7;
    --canvas_cold: hsl(216, 20%, 95%);

    /** MAIN NAV */
    --mainNav_backgroundColor: #001733;
    --mainNav_middleBtnColor: #001733;
    --mainNav_middleBtnColorActive: #0076dd;
    --mainNav_middleBtnHover: #0000001a;
    --mainNav_toggleSettingsAccent: 170, 170, 170;

    /** MODULES NAV */
    --moduleNav_backgroundColor: #f3ede2;
    --moduleNav_border: #e0dbd0;
    --moduleNav_toggleSettingsAccent: 97, 86, 63;
    --moduleNav_toggleBtnSelected: #ede7da;
    --moduleNav_toggleBtnHover: #001733;
    --modulesNav_accent: #cfc2a5;
    --modulesNav_moduleSelected: var(--canvas);
    --modulesNav_btnBorder: #c8c1b1;
    --moduleNav_exitHover: #cfc8b2;
    --modulesNav_tabHover: #faf8f2;

    /**BIGCHAT*/
    --chatRooms_background: #d7d3cc;
    --chatRooms_roomActive: #565656;
    --chatRooms_horizontalLine: #565656;

    /** SECONDARY NAV  */
    --secNav_fontColorHighlight: #0076dd;
    --secNav_itemActiveBackground: #dbeeff;
    --secNav_itemHover: #e6e5e2;
    --secNav_itemHoverFontColor: #001733;
    --secNav_mainColor: #001733;
    --secNav_fontColor: #838383;
    --secNav_fontColorDark: #3e3e3e;
    --secNav_itemHoverDark: rgba(230, 229, 226, 0.6);

    /** BROWSE BANNERS */
    --browseBanner_mainColor: #fff;
    --browseBanner_accentColor: #001733;

    /** MEMBERS AND GROUPS */
    --mng_headerFontColor: var(--main_font_color);
    --mng_headerDivider: #c8c1b1;

    /** CHATAREA */
    --messageContainerElevation: 0 1px 0 hsla(240, calc(var(--saturation-factor, 1) * 11.1%), 1.8%, 0.2),
        0 1.5px 0 hsla(240, calc(var(--saturation-factor, 1) * 7.7%), 2.5%, 0.05),
        0 2px 0 hsla(240, calc(var(--saturation-factor, 1) * 11.1%), 1.8%, 0.05);
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Soleil", sans-serif;
    /* font-family: "DM Sans", sans-serif; */
    /* font-family: "Figtree", sans-serif; */
    /* font-family: "Inter", sans-serif; */
    /* font-family: "Plus Jakarta Sans", sans-serif; */
    font-weight: 400;
    font-style: normal;
}

html {
    font-size: 16px;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: "Soleil", sans-serif;
    /* font-family: "DM Sans", sans-serif; */
    /* font-family: "Figtree", sans-serif; */
    /* font-family: "Inter", sans-serif; */
    /* font-family: "Plus Jakarta Sans", sans-serif; */
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    background-color: var(--canvas);
    /* overflow: hidden; */
    content: contain;
}

a {
    text-decoration: none;
}

strong,
b,
strong *,
b * {
    font-weight: 600;
}

em,
i,
em *,
i * {
    font-style: italic;
}

/** GLOBAL CLASSES  */
.FallbackAllOver {
    background-color: var(--canvas);
    display: inline;
    z-index: 99999;
    position: absolute;
    height: 100%;
    width: 100%;
}

.LoaderBig {
    position: absolute;
    z-index: 2000;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: auto !important;
    height: 30% !important;
    aspect-ratio: 1;
    border: 2% solid #0000;
    border-top: 2% solid var(--main_color);
}

/** BLANK_Q  */

.blankQInput,
.blankQResult {
    border: 1px solid rgb(162, 159, 154);
    border: 1px solid #dbd1c5;
    height: 42px;
    font-size: 18px;
    color: var(--blackFont);
    padding: 0px 16px 2px 16px;
    margin: 0px 4px;
    border-radius: 3px;
    margin-bottom: 2px;
    text-align: center;
}

.blankQInput.Suggested {
    text-align: start;
}

.Mobile.blankQInput,
.Mobile.blankQResult {
    height: 36px;
    font-size: 15px;
    padding: 0px 12px 2px 12px;
}

.blankQResult {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-color: var(--correctAnswerBorder);
    background-color: var(--correctAnswerBG);
    padding-bottom: 1px;
    white-space: nowrap;
}

.blankQResult.Correction {
    border-left: 0px;
    border-radius: 0px 3px 3px 0px;
    margin-left: -3px;
    border-color: var(--correctAnswerBorder);
    background-color: var(--correctAnswerBG);
}

.blankQResult.Wrong {
    margin-right: -3px;
    border-right: 0px;
    border-radius: 3px 0px 0px 3px;
    border-color: var(--wrongAnswerBorder);
    background-color: var(--wrongAnswerBG);
}

.blankQResult.Empty {
    color: transparent;
}

.blankQInput:focus {
    outline: none;
    border: 1px solid var(--accent_glowingBlueBright);
    color: var(--accent_glowingBlueBright);
}

.blankQBlank {
    flex: 0 0 auto;
    background-color: #d8edff;
    border-bottom: 2px solid var(--accent_glowingBlueBright);
    width: 20px;
    height: 21px;
    display: inline-block;
    font-size: 14px;
    margin: 0px 2px;
    margin-bottom: -3px;
}

.NoSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

/** LEXICAL ROOTS  */

.RichTextSimple_Root {
    color: var(--blackFont);
    font-size: 15px;
    line-height: 25px;
    font-weight: normal;
}

/** KATEX  */
span.katex,
.katex,
.katex * {
    /* font: normal 1.28em KaTeX_Main, Times New Roman, serif !important; */
    font-family: KaTeX_Main, Times New Roman, serif !important;
    font-weight: 400 !important;
    line-height: 1.28em !important;
}
