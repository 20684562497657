.LabeledSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
}

.LabeledSection.NoMargin {
    margin-bottom: 0px;
}

.Label {
    font-size: 15px;
    font-weight: 400;
    color: #625b50;
    line-height: 38px;
    height: 38px;
    white-space: nowrap;
}

.LabeledSection:hover:not(.NoHover) .Label,
.LabeledSection.Active .Label {
    color: var(--accent_glowingBlueBright);
}

.LabeledSection.Disabled:hover .Label {
    color: #625b50;
}

.InputElement.Active .Label {
    font-weight: 400;
}

.SubLabel {
    margin-top: -8px;
    font-size: 12px;
    color: #817c73;
    margin-bottom: 6px;
    cursor: pointer;
    white-space: nowrap;
}

.LabeledSection.NoHover .SubLabel {
    cursor: initial;
}

.LabeledSection.Active .SubLabel,
.LabeledSection:hover:not(.NoHover) .SubLabel {
    color: var(--accent_glowingBlueBright);
}

.Content {
    flex: 0 0 auto;
}

.Disabled .Content {
    opacity: 0.6;
    pointer-events: none;
}
