.InputInput {
    display: flex;
    flex: 2;
    border: 1px solid #999;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-radius: 5px;
    min-width: 0;
    width: 356px;
}

.LabelClass {
    margin-top: 12px;
    margin-bottom: 12px;
}
