.Button {
    width: fit-content;
    height: 42px;
    border: none;
    display: flex;
    border-radius: 3px;
    padding: 0px 22px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    --buttonColor: #0076dd;
    --buttonColorHover: #0088ff;
    --buttonIconColor: #ffffff;
    --buttonIconColorHover: #ffffff;
    --buttonFontColor: #ffffff;
    --buttonFontColorHover: #ffffff;
}

.Button.SizeXS {
    height: 32px;
    padding: 0px 14px;
}
.Button.SizeXS.Square {
    width: 32px;
    max-width: 32px;
    min-width: 32px;
}

.Button.SizeS {
    height: 38px;
    padding: 0px 18px;
}

.Button.SizeS.Square {
    width: 38px;
    max-width: 38px;
    min-width: 38px;
}

.Button.SizeM {
    height: 42px;
}

.Button.SizeM.Square {
    width: 42px;
    max-width: 42px;
    min-width: 42px;
}

.Button.SizeL {
    height: 48px;
    padding: 0px 26px;
}

.Button.SizeL.Square {
    width: 48px;
    max-width: 48px;
    min-width: 48px;
}

.Button.SizeXL {
    height: 56px;
    padding: 0px 30px;
}

.Button.SizeXL.Square {
    width: 56px;
    max-width: 56px;
    min-width: 56px;
}

.Button.SizeXXL {
    height: 62px;
    padding: 0px 36px;
}

.Button.SizeXXL.Square {
    width: 62px;
    max-width: 62px;
    min-width: 62px;
}

.Button.NoShadow,
.Button.Disabled {
    box-shadow: none;
}

.Button.Rounded {
    border-radius: 50%;
}

.Button.Disabled {
    cursor: default;
}

/** VARIANTS */
.Button:not(.Disabled).Contained {
    background-color: var(--buttonColor);
}

.Button:hover:not(.Disabled):not(.NoHover).Contained {
    background-color: var(--buttonColorHover);
}

.Button.Disabled.Contained {
    background-color: #cccccc;
}

.Button:not(.Disabled).Outlined {
    background-color: transparent;
    border: 1px solid var(--buttonColor);
}

.Button:hover:not(.Disabled):not(.NoHover).Outlined {
    background-color: var(--buttonColor);
    color: var(--buttonFontColorHover);
}

.Button.Disabled.Outlined {
    border: 1px solid #cccccc;
    background-color: transparent;
}

.Button:not(.Disabled).TextOnly {
    box-shadow: none;
    background-color: transparent;
}

.Button:not(.Disabled):not(.NoHover).TextOnly:hover {
    background-color: var(--buttonFontColorHover);
    color: var(--buttonFontColorHover);
}

.Button.IconOnly {
    padding: 0px 13px;
    min-width: 42px;
}

.Loading.Button.IconOnly {
    padding: 0px 10px;
}

/** VARIANTS -- END */

.Button .Text {
    font-weight: 400;
    font-size: 15px;
    line-height: calc(100% - 1px);
    margin-bottom: 1px;
    color: #ffffff;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: var(--buttonFontColor);
}

.SizeXS .Text {
    font-size: 14px;
}
.SizeS .Text {
    font-size: 14px;
}
.SizeM .Text {
    font-size: 15px;
}
.SizeL .Text {
    font-size: 16px;
}
.SizeXL .Text {
    font-size: 17px;
}
.SizeXXL .Text {
    font-size: 17px;
}

.Button:not(.Disabled):not(.NoHover):hover .Text {
    color: var(--buttonFontColorHover);
}

.Button:not(.Disabled).TextOnly .Text {
    color: var(--buttonFontColor);
    text-decoration: underline;
}

.Button.Disabled.Outlined .Text {
    color: #aeaeae;
}

.Button.TextOnly .Text.Loading {
    color: transparent;
    position: relative;
}

.Button .IconHolderStart,
.Button .IconHolderEnd,
.Button .LoaderHolder {
    height: 100%;
    display: flex;
    align-items: center;
}

.Button .IconHolderStart {
    margin-right: 10px;
}

.Button .IconHolderEnd {
    margin-left: 10px;
}

.Button.IconOnly .IconHolderEnd,
.Button.IconOnly .IconHolderStart {
    margin-left: 0px;
    margin-right: 0px;
}

.Button.Contained .IconHolderStart svg,
.Button.Contained .IconHolderEnd svg {
    fill: var(--buttonIconColor);
}

.Button.Outlined .IconHolderStart svg,
.Button.Outlined .IconHolderEnd svg,
.Button.TextOnly .IconHolderStart svg,
.Button.TextOnly .IconHolderEnd svg {
    fill: var(--buttonIconColor);
}

.Button.Outlined.Disabled .IconHolderStart svg,
.Button.Outlined.Disabled .IconHolderEnd svg,
.Button.TextOnly.Disabled .IconHolderStart svg,
.Button.TextOnly.Disabled .IconHolderEnd svg {
    fill: #aeaeae;
}

.Button.Outlined:hover:not(.Disabled):not(.NoHover) .IconHolderStart svg,
.Button.Outlined:hover:not(.Disabled):not(.NoHover) .IconHolderEnd svg {
    fill: var(--buttonIconColorHover);
}

.Button.TextOnly:hover:not(.Disabled):not(.NoHover) .IconHolderStart svg,
.Button.TextOnly:hover:not(.Disabled):not(.NoHover) .IconHolderEnd svg {
    fill: var(--buttonIconColor);
}

/** BUTTON LOADER  */
.LoaderStart,
.LoaderEnd {
    margin-right: 12px;
    display: flex;
    align-items: center;
}

.LoaderEnd {
    margin-right: 0px;
    margin-left: 12px;
}

.Button.IconOnly .LoaderEnd,
.Button.IconOnly .LoaderStart {
    margin-right: 0px;
    margin-left: 0px;
}

.Circle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Loader::after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.Loader {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    border-top: 3px solid rgba(0, 0, 0, 0);
    border-right: 3px solid rgba(0, 0, 0, 0);
    font-size: 5px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    text-indent: -9999em;
}

.Button.Contained .Loader,
.Button.Contained:hover:not(.NoHover) .Loader,
.Button.Outlined:hover:not(.NoHover) .Loader {
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
}

.Button.Outlined .Loader {
    border-bottom: 2px solid var(--buttonColor);
    border-left: 2px solid var(--buttonColor);
}

.Button.TextOnly .Loader {
    border-bottom: 2px solid var(--buttonColor);
    border-left: 2px solid var(--buttonColor);
}

.Loader:hover {
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
