.EditBtn {
    position: absolute;
    background: #f0f2f5;
    border: 1px solid #0c75cd;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    transition: width 0.2s ease, height 0.2s ease;
    box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.24);
}

.EditBtn.TrashBtn {
    border: 1px solid #b40000;
}

.EditBtn:hover {
    background: #0c75cd;
    border: 1px solid #0c75cd;
}
.EditBtn.TrashBtn:hover {
    background: #b40000;
    border: 1px solid #b40000;
}

.EditBtn svg {
    pointer-events: none;
    fill: #0c75cd;
}

.EditBtn.TrashBtn svg {
    fill: #b40000;
}

.EditBtn:hover svg {
    fill: #fff;
}

.Disabled.EditBtn {
    background: #e7e7e7;
    border: 1px solid #c4c4c4;
    cursor: default;
}

.Disabled.EditBtn svg {
    fill: #a5a5a5;
}

.DownIcon {
    transform: rotate(90deg);
}

.UpIcon {
    transform: rotate(-90deg);
}
