.ResourcePicker {
    width: calc(100% - 30px);
    position: absolute;
    bottom: calc(100% - 8px);
    z-index: 9;
    transform: translateX(0px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    contain: content;
    background-color: #f1ebe2;
    border: 1px solid #d4cec0;
    border-radius: 4px;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.22);
}

.RT {
    min-width: 694px;
    max-width: 694px;
    position: relative;
}

.ResourcePicker:focus {
    outline: none;
}

.Header {
    display: flex;
    height: 36px;
    align-items: center;
}

.Header .Label {
    color: var(--accent_glowingBlueBright);
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 6px;
    margin-left: 14px;
    margin-top: 7px;
    display: flex;
}

.Label .nRsrcs {
    color: #fff;
    background-color: var(--accent_glowingBlueBright);
    font-weight: 500;
    min-width: 20px;
    padding: 0px 6px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    margin-left: 5px;
}

.KeysTooltip {
    display: flex;
    margin-left: auto;
    column-gap: 18px;
    padding-right: 12px;
}

.Tip {
    display: flex;
}

.Tip .Text {
    font-size: 12px;
    line-height: 17px;
    color: #4c473b;
}

.ResourcePicker .Resources {
    padding: 0px 8px 6px 6px;
    max-height: 234px;
}

.Resources .Resource {
    height: 38px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: 8px;
    cursor: pointer;
}

.Resource:hover {
    background-color: white;
}

.Resource.PreventHover:hover:not(.Selected) {
    background-color: #f1ebe2;
}

.Resource.Selected {
    background-color: white;
}

.RsrcTitle {
    margin-left: 8px;
    font-size: 14px;
    color: #38342a;
}

.Resource:hover .RsrcTitle {
    color: var(--accent_glowingBlueBright);
}

.Resource .EnterIcon {
    margin-left: auto;
    margin-right: 18px;
    display: flex;
    align-items: center;
}

.Resource .EnterIcon svg {
    fill: #4c473b;
}
