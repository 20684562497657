.Dropdown {
    z-index: 10;
    display: block;
    position: fixed;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    min-height: 40px;
    background-color: #fff;
    position: absolute;
    left: 28px;
    top: -28px;
}

.Dropdown .Item {
    margin: 0 4px 0 4px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 3px;
    border: 0;
    max-width: 250px;
    min-width: 100px;
}

.Dropdown .Item.fontsize-item,
.Dropdown .Item.fontsize-item .Text {
    min-width: unset;
}

.Dropdown .Item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.Dropdown .Item:first-child {
    margin-top: 8px;
}

.Dropdown .Item:last-child {
    margin-bottom: 8px;
}

.Dropdown .Item:hover {
    background-color: #eee;
}

.Dropdown .Item .Text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
}

.Dropdown .Item .Icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.Dropdown .Divider {
    width: auto;
    background-color: #dedede;
    margin: 4px 8px;
    height: 1px;
}

.ActionButtonContainer {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
}

.ActionButton {
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    position: relative;
    border-radius: 15px;
    color: #222;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 12px;
}

.MenuRoot {
    position: relative;
    /* width: 5px;
    height: 5px; */
    /* background-color: orange; */
}

.ActionButton:hover {
    background-color: #c3c3c3;
}

.ActionButton svg {
    fill: #353535;
    transform: rotate(90deg);
}

.Line {
    border: none;
    margin: 6px 0;
    cursor: pointer;
    height: 1px;
    background-color: #d7d7d7;
}
