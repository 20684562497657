.BlockControl {
    display: block;
    right: 10px;
    width: auto !important;
    height: 34px;
    box-sizing: border-box;
    top: 16px;
    z-index: 10;
    border-radius: 8px;
    border: 1px solid rgb(206, 208, 212);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 8px 13px 8px 9px;
}

.BlockControl:hover {
    background-color: #f1efec;
}

.BlockTypeIcon {
    display: flex;
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 6px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.BlockControl:hover .BlockTypeIcon {
    opacity: 1;
}

.BlockControl:hover svg {
    fill: var(--main_color);
}

.BlockControl:hover span {
    color: var(--main_color);
}

.DropDownItem {
    margin: 0px 6px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 4px;
    border: 0;
    max-width: 240px;
    min-width: 100px;
}
.DropDownItem:hover {
    background-color: #f1efec;
}

.DropDownItem.Active {
    background-color: #eae7e4;
}

.DropDownItem .Icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.Plus {
    background-image: url(../../icons/plus.svg);
}

.Image {
    background-image: url(../../icons/file-image.svg);
}

.HorizontalRule {
    background-image: url(../../icons/horizontal-rule.svg);
}

.CaretRight {
    background-image: url(../../icons/caret-right-fill.svg);
}

.Equation {
    background-image: url(../../icons/plus-slash-minus.svg);
}

.Youtube {
    background-image: url(../../icons/youtube.svg);
}

.Vimeo {
    background-image: url(../../icons/vimeo.svg);
}

.Table {
    background-image: url(../../icons/table.svg);
}

.DropDownItem .Text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
    font-size: 15px;
    margin-bottom: 1px;
}
