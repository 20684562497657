.Switch {
    position: relative;
    display: inline-block;
    height: 16px;
    width: 46px;
    border-radius: 16px;
    align-items: center;
    margin: 4px 1px;
}
.Switch input {
    opacity: 0;
}

.Slider {
    position: absolute;
    display: inline-flex;
    height: 16px;
    width: 46px;
    background-color: #c4c4c4;
    border-radius: 16px;
    align-items: center;
    cursor: pointer;
    left: 0;
    top: 0;
    transition: all 400ms;
}

.Slider::after {
    content: "";
    flex-grow: 0;
    flex-shrink: 0;
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    left: -1px;
    cursor: pointer;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    transition: all 400ms;
}

.Switch input:checked + .Slider::after {
    left: calc(100% - 23px);
    background-color: #80b9ee;
}

.Switch input:checked + .Slider {
    background-color: #196fbe;
}
