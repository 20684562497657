.BlockControl {
    display: block;
    right: 10px;
    width: auto !important;
    height: 34px;
    box-sizing: border-box;
    top: 16px;
    z-index: 10;
    border-radius: 8px;
    border: 1px solid rgb(206, 208, 212);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 8px 13px 8px 9px;
}

.BlockControl:hover {
    background-color: #f1efec;
}

.BlockTypeIcon {
    display: flex;
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.BlockControl:hover .BlockTypeIcon {
    opacity: 1;
}

.BlockControl:hover svg {
    fill: var(--main_color);
}

.BlockControl:hover span {
    color: var(--main_color);
}

.DropDownItem {
    margin: 0px 6px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 4px;
    border: 0;
    max-width: 250px;
    min-width: 100px;
}
.DropDownItem:hover {
    background-color: #f1efec;
}

.DropDownItem.Active {
    background-color: #eae7e4;
}

.DropDownItem .Icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.paragraph {
    background-image: url(../../icons/text-paragraph.svg);
}

.h1 {
    background-image: url(../../icons/type-h1.svg);
}

.h2 {
    background-image: url(../../icons/type-h2.svg);
}

.h3 {
    background-image: url(../../icons/type-h3.svg);
}

.h4 {
    background-image: url(../../icons/type-h4.svg);
}

.h5 {
    background-image: url(../../icons/type-h5.svg);
}

.h6 {
    background-image: url(../../icons/type-h6.svg);
}

.quote {
    background-image: url(../../icons/chat-square-quote.svg);
}

.ul,
.bullet {
    background-image: url(../../icons/list-ul.svg);
}

.ol,
.number {
    background-image: url(../../icons/list-ol.svg);
}

.code {
    background-image: url(../../icons/code.svg);
}

.checkList {
    background-image: url(../../icons/square-check.svg);
}

.code {
    background-image: url(../../icons/code.svg);
}

.DropDownItem .Text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
    font-size: 15px;
    margin-bottom: 1px;
}

/* 
.BlockControl button {
    border: 1px solid white;
    background-color: #fff;
    display: block;
    transition: background-color 0.1s ease;
    cursor: pointer;
    outline: none;
    border-radius: 8px;
    padding: 3px;
}

.BlockControl button:hover {
    background-color: #efefef;
}

.BlockControl button:focus-visible {
    border-color: blue;
}

.BlockControl span.block-type {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
} */
