.ToolbarMaxPlugin {
    display: flex;
    background: #fff;
    vertical-align: middle;
    column-gap: 1px;
    height: 34px;
}

.ToolbarMaxPlugin .ToolbarBtn {
    width: 34px;
    height: 34px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 4px;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
}

.ToolbarBtn:hover,
.ToolbarBtn.ColorPicker.Show {
    background-color: #f1efec;
}

.ToolbarBtn .Icon {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.64;
}
.ToolbarBtn:hover .Icon {
    opacity: 1;
}

.Icon.Bold {
    background-image: url(../../icons/type-bold.svg);
}

.Icon.Italic {
    background-image: url(../../icons/type-italic.svg);
}

.Icon.Underline {
    background-image: url(../../icons/type-underline.svg);
}

.Icon.Strikethrough {
    background-image: url(../../icons/type-strikethrough.svg);
}

.Icon.Link {
    background-image: url(../../icons/link.svg);
}

.Icon.Redo {
    background-image: url(../../icons/arrow-clockwise.svg);
}

.Icon.Undo {
    background-image: url(../../icons/arrow-counterclockwise.svg);
}

.Icon.BulletList {
    background-image: url(../../icons/list-ul.svg);
}

.Icon.NumberedList {
    background-image: url(../../icons/list-ol.svg);
}

.Icon.Quote {
    background-image: url(../../icons/chat-square-quote.svg);
    padding: 1px;
}

.Icon.Blanks {
    background-image: url(../../icons/blanks.svg);
    margin-right: 6px !important;
    margin-left: 10px;
    margin-bottom: -4px;
}

.ToolbarBtn.Active {
    background-color: #eae7e4;
}

.ToolbarBtn.Active .Icon {
    opacity: 1;
}

.ToolbarBtn svg {
    fill: #5c5c5c;
}

.ToolbarBtn:hover svg,
.ToolbarBtn.ColorPicker.Show svg {
    fill: #000;
}

.ToolbarBtn .Text {
    color: #5c5c5c;
    font-size: 15px;
    margin-right: 12px;
}

.ToolbarDivider {
    width: 1px;
    background-color: #eee;
    margin: 0 4px;
}

.ToolbarBtn.ColorPicker {
    width: 60px;
}

.SelectedFontIcon {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.SelectedFontIcon .Text {
    font-size: 16px;
    line-height: 22px;
    margin-top: -2px;
    font-weight: 500;
    color: #5c5c5c;
    margin-right: 0px;
}

.ToolbarBtn:hover .SelectedFontIcon .Text {
    color: #000;
}

.SelectedFontIcon .FontColor {
    height: 4px;
    width: 22px;
    background-color: black;
}

.SelectedFontIcon .Icon {
    margin-top: 0px;
    margin-bottom: 2px;
}

.InsertBlank {
    width: fit-content !important;
}

.ModalContainer {
    position: relative;
    z-index: 10000;
}