
.LinkPreview {
    padding-bottom: 12px;
}


.ImageWrapper {
    text-align: center;
}

.Image {
    max-width: 100%;
    max-height: 250px;
    margin: auto;
}

.Domain {
    color: #999;
    font-size: 90%;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 4px;
}

.Title {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 4px;
}

.Description {
    color: #999;
    font-size: 90%;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 4px;
}

.Glimmer {
    background: #f9f9f9;
    border-radius: 8px;
    height: 18px;
    margin-bottom: 8px;
    margin-left: 12px;
    margin-right: 12px;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: glimmer-animation;
}

@keyframes glimmer-animation {
    0% {
        background: #f9f9f9;
    }
    .50% {
        background: #eeeeee;
    }
    .100% {
        background: #f9f9f9;
    }
}
