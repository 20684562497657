.CreateFillInBlanks {
    margin-top: 12px;
}

.SolutionHolder {
    min-height: 25px;
}

.EvalSection {
    display: flex;
}

.DropdownHolder {
    margin-right: 24px;
}

/** ANSWERS  */
.AnswersContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.AddBtnHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    margin-top: 6px;
}

.Answer {
    display: flex;
    width: 100%;
}

/* .Answer .TextInputArea:focus {
    outline: none !important;
    border: 1px solid #9c9c9c;
} */

/* .Answer .TextInputArea {
    flex: 1;
    min-height: 50px;
    min-height: 50px;
    width: 100%;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    resize: none;
    padding: 12px 18px;
    font-size: 15px;
    line-height: 24px;
} */

.PointInput {
    flex: 0 0 auto;
    border: 1px solid #bfb7a9;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 10px;
    display: flex;
    height: 46px;
    width: 60px;
    text-align: center;
    padding: 5px 5px 5px 8px;
    font-size: 17px;
    background-color: #fff;
}

.PointInput:focus {
    outline: none !important;
    border: 1px solid #0c75cd;
}

.ShowOptionsSection {
    /* background-color: orange; */
    margin-bottom: 10px;
}

.AnswerAlert {
    background-color: rgb(255, 147, 147);
    color: darkred;
    font-size: 13px;
    padding: 2px 6px;
    margin-top: -8px;
    z-index: 0;
}
