.InsertImageDialog {
    padding: 36px 42px 40px 42px;
    width: 440px;
}

.Buttons {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.URLInput {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 22px;
}

.FileInput {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 22px;
}

.ChoiceButton {
    width: 100%;
    justify-content: center;
}

/** FILE INPUT  */

.InputWrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    flex-direction: column;
}
