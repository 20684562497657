.LexicalMax {
    background-color: #fff;
    border: 1px solid #bfb7a9;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    position: relative;
}

.LexicalMax.ResourceStyle {
    border: none;
    background-color: var(--canvas);
}

.LexicalMax.EditFocus {
    cursor: text;
}

.ToolbarContainer {
    margin: 4px 6px 0px 4px;
}

.ResourceStyle .ToolbarContainer {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    margin-top: -24px;
    width: calc(100% + 48px);
    padding: 10px 24px 10px 32px;
    background-color: #fff;
    position: sticky;
    top: 0px;
    z-index: 1;
}

.EditorInner {
    position: relative;
    width: 100%;
}

.TextInput {
    min-height: 90px;
    resize: none;
    outline: none;
    width: 100%;
    border: none;
    margin-top: 0px;
    padding: 4px 12px 12px 12px;
    background-color: transparent;
    text-align: left;
}

.ResourceStyle .TextInput {
    padding: 0px 0px 0px 0px;
}
