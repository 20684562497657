.LexicalSelect {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    --select-border: #d4cfc3;
    --select-focus: #101484;
    --select-arrow: var(--select-border);
}

.Label {
    font-size: 15px;
    font-weight: 400;
    color: #625b50;
    line-height: 38px;
    height: 38px;
    white-space: nowrap;
    width: 100%;
}

.Select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
    outline: none;
    border: 1px solid var(--select-border);
    border-radius: 3px;
    padding: 10px 12px;
    font-size: 15px;
    cursor: pointer;
    line-height: 1.4;
    background: #ede7da;
    width: 100%;
    color: var(--blackFont);
}
