.ModalBackground {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
}

.Modal {
    background-color: white;
    margin: 0 auto;
    border-radius: 4px;
}
