.SpaceLoader {
    position: absolute;
    z-index: 2000;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: auto;
    height: 14%;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: scale 1.4s linear infinite;
    background-color: var(--spc_icon_backgroundColor);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    /* box-shadow: 0px 1px 2px 0px rgba(40, 39, 39, 0.24); */
    transition: all 1.4s;
}

.SpaceIcon {
    width: 56%;
    height: 56%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1.4s;
}

@keyframes scale {
    0% {
        /* box-shadow: 0px 1px 2px 0px rgba(40, 39, 39, 0.24); */
        transform: scale(1);
    }

    25% {
        transform: scale(1.15);
    }

    50% {
        transform: scale(1.3);
        /* box-shadow: 0px 2px 8px 0px rgba(40, 39, 39, 0.28); */
    }

    75% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
        /* box-shadow: 0px 1px 2px 0px rgba(40, 39, 39, 0.24); */
    }
}
