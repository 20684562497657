.LexicalDropDown {
    position: relative;
    z-index: 1000;
}

.Dropdown {
    z-index: 1000;
    display: block;
    position: absolute;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.28);
    border-radius: 4px;
    min-height: 40px;
    background-color: #fff;
    padding: 6px 0px;
}

.BlockIcon {
    opacity: 0.64;
}

.IconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 11px;
}

.ChevDown {
    transform: rotate(90deg);
    fill: #5c5c5c;
}

.Text {
    color: #5c5c5c;
    font-size: 15px;
}
