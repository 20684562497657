.TextInputElement {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    width: fit-content;
    position: relative;
}

.TextInputElement.FullWidth {
    width: 100%;
}

.Label {
    font-size: 16px;
    font-weight: 400;
    color: #625b50;
    line-height: 38px;
    height: 38px;
    cursor: pointer;
    white-space: nowrap;
}

.TextInputElement.Disabled .Label {
    cursor: initial;
}

.TextInputElement:hover .Label,
.TextInputElement.Active .Label {
    color: #0c75cd;
}
.TextInputElement.Active .Label {
    font-weight: 400;
}

.InputWrapper {
    display: flex;
    height: 46px;
    border: 1px solid #bfb7a9;
    border-radius: 4px;
    background-color: white;
    padding-left: 12px;
    padding-right: 12px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.Disabled .InputWrapper {
    background-color: var(--canvas);
}

.TextInputElement:hover:not(.Disabled) .InputWrapper,
.TextInputElement.Active:not(.Disabled) .InputWrapper {
    border: 1px solid #0c75cd;
}

.TextInputElement .InputWrapper:focus {
    border: 1px solid #0c75cd;
}

.TextInput {
    line-height: 44px;
    padding-bottom: 1px;
    font-size: 15px;
    color: black;
    font-weight: 400;
    width: 100%;
    border: none;
    background-color: transparent;
}

.TextInput:focus {
    color: black;
    outline: none;
}

.TextInputElement .IconHolder {
    height: 100%;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* pointer-events: none; */
}

.TextInputElement .IconHolder svg {
    fill: #928c80;
}

.TextInputElement:hover .IconHolder svg {
    fill: #0c75cd;
}
.TextInputElement.Active .IconHolder svg {
    fill: #0c75cd;
}

.TextInputElement .IconHolder.Password {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 8px;
    margin-top: 2px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
}

.TextInputElement .IconHolder.Password:hover svg {
    fill: var(--main_color);
}

.CharLimit {
    margin-top: auto;
    font-size: 13px;
    color: #928c80;
    margin-left: 5px;
    position: absolute;
    right: 0px;
    bottom: -19px;
    transition: all 0.2s;
    opacity: 0;
}

.TextInputElement:hover .CharLimit {
    opacity: 1;
}

.Active .CharLimit {
    opacity: 1;
}

.HideSpan {
    position: absolute !important;
    opacity: 0;
    z-index: -100;
    white-space: pre;
    width: fit-content;
}
