.ColorPickerWrapper {
    padding: 14px 24px 24px 24px;
}

.ColorPickerBasicColor {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 0;
    padding: 0;
}

.PickerTextInput {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
}
.PickerTextInput .Label {
    color: #424242;
    margin-right: 10px;
}

.ColorPickerColor {
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 182px;
    height: 42px;
    margin-right: 12px;
}

.ColorPickerBasicColor button {
    border: 1px solid #ccc;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    list-style-type: none;
    position: relative;
    overflow: hidden;
}

.ColorPickerBasicColor button.Active {
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.ColorPickerSat {
    width: 100%;
    position: relative;
    margin-top: 15px;
    height: 150px;
    background-image: linear-gradient(transparent, black), linear-gradient(to right, white, transparent);
    user-select: none;
}

.ColorPickerSatCursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 15px #00000026;
    box-sizing: border-box;
    transform: translate(-10px, -10px);
}

.ColorPickerHue {
    width: 100%;
    position: relative;
    margin-top: 15px;
    height: 12px;
    background-image: linear-gradient(
        to right,
        rgb(255, 0, 0),
        rgb(255, 255, 0),
        rgb(0, 255, 0),
        rgb(0, 255, 255),
        rgb(0, 0, 255),
        rgb(255, 0, 255),
        rgb(255, 0, 0)
    );
    user-select: none;
    border-radius: 12px;
}

.ColorPickerHueCursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    box-shadow: #0003 0 0 0 0.5px;
    box-sizing: border-box;
    transform: translate(-10px, -4px);
}

.ColorPickerLabel {
    margin-top: 12px;
    color: #424242;
    margin-bottom: 4px;
}

.DiagonaleLine {
    width: calc(1.33 * 18px);
    transform: rotate(-45deg);
    transform-origin: top left;
    border-top: 3px solid red;
    position: absolute;
    top: 100%;
    left: -2px;
    box-sizing: border-box;
}
