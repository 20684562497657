.InsertTableDialog {
    padding: 36px 42px 40px 42px;
    width: 440px;
}

.Spacer {
    height: 22px;
}

.ChoiceButton {
    width: 100%;
    justify-content: center;
    margin-top: 22px;
}
