.InsertImageDialog {
    padding: 36px 42px 40px 42px;
    width: 440px;
}

.ConfirmBtn {
    margin-left: auto;
}

.SelectHolder {
    margin-bottom: 1rem;
    width: 100%;
}
