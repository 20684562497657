.ResourceIconWrapper {}

.IconDisk {
    width: 22px;
    height: 22px;
}

.IconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
}
.MonthDayContainer {
    width: 38px;
    height: 48px;
    /* background-color: orange; */
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}


.MonthDayContainer .Month {
    font-weight: 700;
    font-size: 12px;
    color: #D90A00;
    transition: all 0.3s;
}
.MonthDayContainer .WeekDay {
    font-weight: 400;
    font-size: 12px;
    color: #D90A00;
    transition: all 0.3s;
}

.MonthDayContainer .Day {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    transition: all 0.3s;
    color:#fff;
    /* identical to box height, or 106% */
}
