/** LEXICAL MODAL HEADER  */
.LexicalModalHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 6px;
    position: relative;
}

.LexicalModalHeader .Title {
    font-size: 26px;
    line-height: 38px;
    font-weight: 500;
}

.LexicalModalHeader .CloseBtn {
    position: absolute;
    right: -18px;
    top: -18px;
}

.LexicalModalHeader.NoLine {
    border-bottom: none !important;
}
